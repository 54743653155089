const Footer = () => {
  return (
    <footer className="py-8 bg-dark text-[17.6px] lg:text-[19.2px] font-jost">
      <div className="px-[15px] flex flex-col items-center justify-center">
        <a
          href="https://www.thehaitiangroup.com"
          className="p-4 pt-0 text-[#fafafa]"
        >
          Official Website
        </a>
        <p className="text-white">
          © Copyright {new Date().getFullYear()} Haitian All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
