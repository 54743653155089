import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";

const Apply = () => {
  return (
    <>
      <Header />
      <div className="bg-[#efefef]">
        <Form />
      </div>
      <Footer />
    </>
  );
};

export default Apply;
