import Header from "../components/Header";
import hero from "../assets/picture1-877x873.png";
import { Link } from "react-router-dom";
import awardone from "../assets/tech-awards-2021_logo-240x191.jpg";
import awardtwo from "../assets/blobid0-240x126.png";
import awardthree from "../assets/consumer-choice-award-service-excellence-png-favpng-bntvbabeh9pytzmd96sbmj2mr-240x234.jpg";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="font-jost">
      <Header />
      <section className="py-24 bg-explore bg-[50%_50%] bg-no-repeat bg-cover relative">
        <div className="opacity-80 absolute bg-blue bottom-0 left-0 right-0 top-0" />
        <div className="w-full relative mx-auto md:max-w-[720px] md:px-[15px] lg:px-4 lg:max-w-[960px] xl:max-w-[1140px] md:flex md:items-center">
          <div className="p-4 md:w-1/2">
            <img src={hero} alt="hero" />
          </div>
          <div className="px-[15px] text-center md:w-1/2">
            <h1 className="text-white text-[32px] mb-4 md:text-5xl">
              <strong>URGENTLY HIRING</strong>
            </h1>
            <p className="text-white text-2xl mt-[-3.2px] mb-4">
              Business Intermediaries &amp; Partners
            </p>
            <div className="mt-4 mx-[-9.6px]">
              <Link
                to="/apply"
                className=" text-black inline-flex text-[17px] md:text-[19.2px] bg-yellow m-[9.6px] py-[9.6px] px-[19.2px] shadow-[0_2px_2px_0_rgba(0,0,0,0.2)] rounded font-semibold"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[5rem] md:pt-20 md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] mx-auto text-dark">
        <div className="lg:flex lg:justify-center">
          <div className="px-[15px] lg:px-4 lg:max-w-[83%]">
            <h4 className="text-[25px] md:text-[35px] mb-8">
              <strong> About The Company</strong>
            </h4>
            <p className="text-[17.6px] md:text-[19.2px] mb-4">
              Founded in 1966, with over 50 years of growth through
              entrepreneurial brilliance, Haitian Group has developed into a
              holding company with multi-national operation and total assets
              exceeding RMB21.1 billion. The Group now has mainly covered five
              industries, namely plastics machinery (“Haitian International
              Holdings Limited” listed in Hong Kong Stock Exchange), CNC
              machines (“Ningbo Haitian Precision Holdings Limited” listed in
              Shanghai Stock Exchange), drive and control systems (“Haitian
              Drive”) and die casting machines (“Haitian Die Casting”), smart
              solutions (“Haitian Smart”).{" "}
              <a
                href="https://www.thehaitiangroup.com/company"
                className="text-[#6592e6]"
              >
                <strong>Read More</strong>
              </a>
            </p>
          </div>
        </div>

        <section className="pt-12 pb-16 px-[15px] lg:px-8 xl:px-4 lg:flex lg:flex-col lg:items-center lg:justify-center xl:mx-[-16px]">
          <div className="mb-8 pb-6 lg:max-w-[75%] xl:max-w-full">
            <p className="text-[17.6px] md:text-[19.2px]">
              To fulfill the rapidly increasing demand for On-Deck Machinery,
              The Haitian Group has taken on a new challenge of expanding her
              products to the entire America and outside Europe. We are looking
              forward to establish our offices and sales centers especially in
              the United States of America (USA).
              <br />
              <br />
              Until the centers are established, we are in need of reliable
              individual/company (irrespective of business class or type) that
              can stand as an intermediary between our customers/client in the
              United States of America (USA).We are seeking for candidates who
              are team players, self-motivated, physically fit, enjoy working
              with people, and are determined to succeed at whatever they do.
              <br />
              <br />
              The Haitian Group is actively seeking candidates including women
              and minorities. Prerequisites for the position of Representative
              include the following:
              <br />
            </p>
          </div>
          <div className="md:text-[17.6px] md:w-[66%] mx-auto">
            <div className="flex items-center mb-8">
              <div className="mr-4 bg-yellow w-[60px] h-[60px] rounded-[50%] flex items-center justify-center">
                <span className="text-black text-[32px]">1</span>
              </div>
              <div>
                <h4 className="text-[25px] md:text-[35px] mb-3">
                  <strong>Prerequisites&nbsp;</strong>
                </h4>
                <h5 className="mb-2">
                  <strong>Valid means of identification</strong>&nbsp;
                  <br />
                  <strong>+</strong>SSN
                  <br />
                  <strong>+</strong>Drivers License, <br />
                  <strong>+ </strong>Or any&nbsp;Government approved ID
                  <div>
                    <br />
                  </div>
                </h5>
              </div>
            </div>
            <div className="flex items-center mb-8">
              <div className="mr-4 bg-yellow w-[60px] h-[60px] rounded-[50%] flex items-center justify-center">
                <span className="text-black text-[32px]">2</span>
              </div>
              <div>
                <h5 className="mb-2">
                  <strong>
                    Proof of Residency in United States of America{" "}
                  </strong>
                  <br />
                  <strong>+</strong>Utility Bill of any kind&nbsp;
                  <br />
                  <strong>+ </strong>Evidence of&nbsp;Monthly mortgage
                  <br />
                  <strong>+ </strong>Or&nbsp;Residential rental/lease agreement
                </h5>
              </div>
            </div>
            <div className="flex items-center mb-8">
              <div className="mr-4 bg-yellow w-[60px] h-[60px] rounded-[50%] flex items-center justify-center">
                <span className="text-black text-[32px]">3</span>
              </div>
              <div>
                <h5 className="mb-2">
                  <strong>Others</strong>
                  <br />
                  <strong>+</strong>Basic knowledge of how the internet works
                  <div>
                    <strong>+ </strong>Ability to write and reply emails
                  </div>
                </h5>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="py-20 text-center md:max-w-[720px] lg:max-w-[960px] mx-auto">
        <div className="px-[15px]">
          <h3 className="text-dark text-[32px] md:text-5xl">
            <strong>Get paid up to $4,000 Monthly</strong>
          </h3>
        </div>
      </section>

      <section className="py-24 bg-teal text-white text-center md:px-8 lg:flex">
        <div className="border-b border-white px-[15px] py-[30px] lg:border-b-0 lg:border-r lg:w-[50%] lg:px-4">
          <h3 className=" text-[#fafafa] text-[25.7px] md:text-[35.2px] mb-8">
            <strong>Benefits</strong>
          </h3>
          <p className="text-[16.8px] md:text-[17.6px] mb-8">
            At The Haitian Group our benefits can vary by location, the number
            of regularly scheduled hours you work, length of employment, and job
            status such as seasonal or temporary employment.
          </p>
          <Link
            to="/apply"
            className="text-dark bg-yellow font-semibold shadow-[0_2px_2px_0_rgba(0,0,0,0.2)] py-[9.6px] px-[19.2px] rounded m-[9.6px]"
          >
            APPLY
          </Link>
        </div>
        <div className="px-[15px] py-[30px] lg:w-[50%] lg:px-4">
          <h3 className=" text-[#fafafa] text-[25.7px] md:text-[35.2px] mb-8">
            <strong>Compensation</strong>
          </h3>
          <p className="text-[16.8px] md:text-[17.6px] mb-8">
            The Haitian Group Contract Position provides you an opportunity to
            receive up to $5,200 monthly and as well defer compensation for your
            long-term savings and includes a company match. The plan offers a
            variety of investment options to help you reach your financial
            goals.
          </p>
          <Link
            to="/apply"
            className="text-dark bg-yellow font-semibold shadow-[0_2px_2px_0_rgba(0,0,0,0.2)] py-[9.6px] px-[19.2px] rounded m-[9.6px]"
          >
            APPLY
          </Link>
        </div>
      </section>

      <section className="px-[15px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] mx-auto md:flex md:justify-center">
        <div className="p-12 mb-[37px] text-[17.44px] bg-[#fff0b0] rounded border-l-[#ff6666] border-l-[3px] md:w-[83%]">
          <h5 className="mb-2">
            <strong>Scam Alert !!!</strong>
          </h5>
          <p className="mb-4">
            <strong>
              The management of The Haitian Group would never request any money
              for the purpose of employment or other hiring process.&nbsp;
              <br />
              <br />
              We would only contact you through the following official
              emails&nbsp;
              <br />
              <a
                href="mailto:hr@thehaitiangroup.com"
                className="text-red font-normal"
              >
                hr@thehaitiangroup.com
              </a>
              <br />
              <a
                href="mailto:careers@thehaitiangroup.com"
                className="text-red font-normal"
              >
                careers@thehaitiangroup.com
              </a>
              <br />
              <a
                href="mailto:accounting@thehaitiangroup.com"
                className="text-red font-normal"
              >
                accounting@thehaitiangroup.com
              </a>
              <br />
            </strong>
          </p>
        </div>
      </section>

      <section className="pb-4">
        <div className="px-[15px] mb-4 mx-auto md:flex md:justify-center md:max-w-[720px]">
          <div className="mb-4 py-4 md:w-[25%]">
            <img src={awardone} width={120} className="mx-auto" alt="award" />
          </div>
          <div className="mb-4 py-4 md:w-[25%]">
            <img src={awardtwo} width={120} className="mx-auto" alt="award" />
          </div>
          <div className="mb-4 py-4 md:w-[25%]">
            <img src={awardthree} width={120} className="mx-auto" alt="award" />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
