import { IoMenuOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { useState } from "react";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="py-2 px-4 bg-white md:mx-[30px] xl:mx-[270px] font-jost">
      <div className="flex justify-between items-center">
        <Link to="/">
          <img src={logo} alt="logo" width={60} height={60} />
        </Link>
        <button className="lg:hidden" onClick={toggleMenu}>
          {menuOpen ? (
            <IoMdClose className="text-4xl" />
          ) : (
            <IoMenuOutline className="text-4xl" />
          )}
        </button>
        <nav className="hidden lg:flex lg:items-center">
          <a
            className="text-dark font-medium text-lg"
            href="https://www.thehaitiangroup.com"
          >
            <strong>Official Website</strong>
          </a>
          <Link
            to="/apply"
            className="py-[9.6px] px-[19.2px] m-[9.6px] bg-yellow text-lg font-semibold shadow-[0_2px_2px_0_rgba(0,0,0,0.2)] ml-8"
          >
            APPLY NOW
          </Link>
        </nav>
      </div>

      {menuOpen && (
        <div className="fixed bg-white left-0 right-0 flex flex-col items-center justify-center lg:hidden z-50">
          <a
            className="text-dark font-medium text-lg m-3"
            href="https://www.thehaitiangroup.com"
          >
            <strong>Official Website</strong>
          </a>
          <Link
            to="/apply"
            className="py-[9.6px] px-[19.2px] m-[9.6px] bg-yellow text-lg font-semibold shadow-[0_2px_2px_0_rgba(0,0,0,0.2)]"
          >
            APPLY NOW
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;
