import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAFYfsaNnFSXOXggpeYOe6IgRkU0y7n6TI",
  authDomain: "haitian-11d33.firebaseapp.com",
  projectId: "haitian-11d33",
  storageBucket: "haitian-11d33.appspot.com",
  messagingSenderId: "943373621118",
  appId: "1:943373621118:web:a3d54e43476bd79162052f",
};

const app = initializeApp(firebaseConfig);

export default app;
