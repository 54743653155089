import { useForm } from "react-hook-form";
import logo from "../assets/form-logo.png";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../firebase";
import { useNavigate } from "react-router-dom";

function Form() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const file = data.resume[0];

      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //   const progress =
          //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          setLoading(false);
          toast.error("Something went wrong");
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const submit = async () => {
              try {
                await axios.post(
                  "https://haitian-api.onrender.com/api/submission",
                  {
                    firstName: data.firstName,
                    middleName: data.middleName,
                    lastName: data.lastName,
                    birthday: new Date(data.birthday),
                    address: data.address,
                    addressTwo: data.addressTwo,
                    city: data.city,
                    state: data.state,
                    zipCode: data.zipCode,
                    email: data.email,
                    phone: data.phone,
                    linkedIn: data.linkedIn,
                    position: data.position,
                    referral: data.referral,
                    availableStartDate: new Date(data.availableStartDate),
                    resume: downloadURL,
                    coverLetter: data.coverLetter,
                  }
                );

                setLoading(false);
                toast.success("Application Submitted");
                setTimeout(() => {
                  navigate("/");
                }, 2000);
              } catch (error) {
                setLoading(false);
                toast.error("Something went wrong");
              }
            };

            submit();
          });
        }
      );
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="py-12 bg-[#e0f0fd] flex flex-col items-center justify-center text-[#2C3345]">
      <img src={logo} alt="logo" width={180} className="mb-3" />
      <div className="bg-white w-[95%] lg:w-3/4 xl:w-[45%]">
        <div className="py-7 px-[14px] lg:px-10 text-center border-b border-b-[#d7d8e1]">
          <h1 className="text-[32px] font-semibold">Job Application Form</h1>
          <p>
            Thank you for your interest in joining our team! Please fill out the
            following job application form to be considered for the position.
            Your responses will help us assess your qualifications and
            suitability for the role. We appreciate your time and look forward
            to reviewing your application
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="py-6 px-4 lg:px-10">
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="w-full md:w-1/3 mb-4">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name<span className="text-red">*</span>
              </label>
              <input
                id="firstName"
                {...register("firstName", { required: true })}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
              {errors.firstName && (
                <span className="text-red text-xs">
                  {errors.firstName.message || "This field is required"}
                </span>
              )}
            </div>
            <div className="w-full md:w-1/3 mb-4">
              <label
                htmlFor="middleName"
                className="block text-sm font-medium text-gray-700"
              >
                Middle Name
              </label>
              <input
                id="middleName"
                {...register("middleName")}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="w-full md:w-1/3 mb-4">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name<span className="text-red">*</span>
              </label>
              <input
                id="lastName"
                {...register("lastName", { required: true })}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
              {errors.lastName && (
                <span className="text-red text-xs">
                  {errors.lastName.message || "This field is required"}
                </span>
              )}
            </div>
          </div>
          <div className="mb-4 md:w-1/2">
            <label
              htmlFor="birthday"
              className="block text-sm font-medium text-gray-700"
            >
              Birth Date<span className="text-red">*</span>
            </label>
            <input
              id="birthday"
              type="date"
              {...register("birthday", { required: true })}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
            {errors.birthday && (
              <span className="text-red text-xs">
                {errors.birthday.message || "This field is required"}
              </span>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Address<span className="text-red">*</span>
            </label>
            <input
              id="address"
              {...register("address", { required: true })}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
            {errors.address && (
              <span className="text-red text-xs">
                {errors.address.message || "This field is required"}
              </span>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="addressTwo"
              className="block text-sm font-medium text-gray-700"
            >
              Address Two
            </label>
            <input
              id="addressTwo"
              {...register("addressTwo")}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          <div className="md:flex md:space-x-4">
            <div className="mb-4 md:w-1/2">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <input
                id="city"
                {...register("city")}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="mb-4 md:w-1/2">
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700"
              >
                State/Province
              </label>
              <input
                id="state"
                {...register("state")}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="zipCode"
              className="block text-sm font-medium text-gray-700"
            >
              Postal / Zip Code
            </label>
            <input
              id="zipCode"
              {...register("zipCode")}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          <div className="md:flex md:space-x-4">
            <div className="mb-4 md:w-1/2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address<span className="text-red">*</span>
              </label>
              <input
                id="email"
                type="email"
                {...register("email", { required: true })}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
              {errors.email && (
                <span className="text-red text-xs">
                  {errors.email.message || "This field is required"}
                </span>
              )}
            </div>
            <div className="mb-4 md:w-1/2">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number<span className="text-red">*</span>
              </label>
              <input
                id="phone"
                type="tel"
                {...register("phone", { required: true })}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
              {errors.phone && (
                <span className="text-red text-xs">
                  {errors.phone.message || "This field is required"}
                </span>
              )}
            </div>
          </div>
          <div className="mb-4 md:w-1/2">
            <label
              htmlFor="linkedIn"
              className="block text-sm font-medium text-gray-700"
            >
              LinkedIn
            </label>
            <input
              id="linkedIn"
              {...register("linkedIn")}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          <div className="md:flex md:space-x-4">
            <div className="mb-4 md:w-1/2">
              <label
                htmlFor="position"
                className="block text-sm font-medium text-gray-700"
              >
                Position Applied<span className="text-red">*</span>
              </label>
              <select
                id="position"
                {...register("position", { required: true })}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              >
                <option value="">Please Select</option>
                <option value="Marketing Associate">Marketing Associate</option>
                <option value="Virtual Assistant">Virtual Assistant</option>
                <option value="Marketing Associate">Marketing Associate</option>
                <option value="Developer">Developer</option>
              </select>
              {errors.position && (
                <span className="text-red text-xs">
                  {errors.position.message || "This field is required"}
                </span>
              )}
            </div>
            <div className="mb-4 md:w-1/2">
              <label
                htmlFor="referral"
                className="block text-sm font-medium text-gray-700"
              >
                How did you hear about us?<span className="text-red">*</span>
              </label>
              <select
                id="referral"
                {...register("referral", { required: true })}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              >
                <option value="">Please Select</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Event">Event</option>
                <option value="Social Media">Social Media</option>
                <option value="Company Website">Company Website</option>
                <option value="Family / Friend">Family / Friend</option>
                <option value="Other">Other</option>
              </select>
              {errors.referral && (
                <span className="text-red text-xs">
                  {errors.referral.message || "This field is required"}
                </span>
              )}
            </div>
          </div>
          <div className="mb-4 md:w-1/2">
            <label
              htmlFor="availableStartDate"
              className="block text-sm font-medium text-gray-700"
            >
              Available Start Date
            </label>
            <input
              id="availableStartDate"
              type="date"
              {...register("availableStartDate")}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="resume"
              className="block text-sm font-medium text-gray-700"
            >
              Upload Your Resume<span className="text-red">*</span>
            </label>
            <input
              id="resume"
              type="file"
              {...register("resume", { required: true })}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
            {errors.resume && (
              <span className="text-red text-xs">
                {errors.resume.message || "This field is required"}
              </span>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="coverLetter"
              className="block text-sm font-medium text-gray-700"
            >
              Cover Letter<span className="text-red">*</span>
            </label>
            <textarea
              id="coverLetter"
              rows={6}
              {...register("coverLetter", { required: true })}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            ></textarea>
            {errors.coverLetter && (
              <span className="text-red text-xs">
                {errors.coverLetter.message || "This field is required"}
              </span>
            )}
          </div>
          {loading ? (
            <button
              type="button"
              className="bg-dark text-white px-4 py-2 rounded-md w-full md:w-[200px] md:relative md:left-1/2 md:translate-x-[-50%]"
            >
              Applying...
            </button>
          ) : (
            <button
              type="submit"
              className="bg-dark text-white px-4 py-2 rounded-md w-full md:w-[200px] md:relative md:left-1/2 md:translate-x-[-50%]"
            >
              Apply
            </button>
          )}
        </form>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        closeButton={false}
      />
    </div>
  );
}

export default Form;
